<template>
  <div>
    <vx-card class="directors-card">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="teacherData"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :noDataText="noDataText"
      >
        <div slot="header" class="w-full">
          <div class="flex justify-between align-items-center mb-8">
            <div class="vx-breadcrumb md:block hidden">
              <ul class="flex flex-wrap items-center">
                <li class="inline-flex items-center">
                  <a href="javascript:void(0)" class>Manage</a>
                  <span class="breadcrumb-separator mx-2">
                    <span class="feather-icon select-none relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-chevrons-right"
                      >
                        <polyline points="13 17 18 12 13 7" />
                      </svg>
                    </span>
                  </span>
                </li>

                <li class="inline-flex items-center">
                  <a href="javascript:void(0)" class>Teachers</a>
                </li>
              </ul>
            </div>
            <br />
          </div>
          <vs-row
            vs-align="flex-start"
            vs-type="flex"
            vs-justify="left"
            vs-w="12"
            class="mb-8"
          >
            <vs-col
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              vs-xs="12"
              vs-sm="6"
              vs-lg="6"
            >
              <vs-input
                class="w-full search-input"
                icon-pack="feather"
                icon="icon-search"
                v-model="searchQuery"
                placeholder="Search..."
              />
            </vs-col>

            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="left"
              vs-xs="12"
              vs-sm="6"
              vs-lg="6"
            >
              <div class="centerx mx-1">
                <vs-button
                  color="primary"
                  @click="csvUpload.config.popupToUploadCsv = true"
                  type="filled"
                  >Mass Upload</vs-button
                >
                <vs-popup
                  title="Mass Upload"
                  :active.sync="csvUpload.config.popupToUploadCsv"
                >
                  <div class="centerx">
                    <p>
                      “This Mass Upload enables you to upload several Teachers
                      at once.”
                    </p>
                    <p class="mt-2">
                      “The “Upload Template” option will provide a file in which
                      to populate the Teachers details which can be uploaded at
                      the same time. Remember to save as a csv. File.”<br><br>
                      <strong>"Please enter the correct room name and note that the role section must be in the following format."<br>
                      Teacher: teacher<br>
                      Lead teacher: leadTeacher<br><br>
                     </strong>
                    </p>
                    <p class="mt-2">
                      “When the file is ready use the Mass Upload option below
                      to select the file from your saved location which will be
                      followed by an “Import” option.”
                    </p>

                    <div>
                      <vs-input
                        hidden
                        v-model="csvUpload.data.learningCenterId"
                        class="mt-5 w-full"
                        name="CenterId"
                      />
                    </div>

                    <div>
                      <vs-input
                        hidden
                        v-model="csvUpload.data.directorId"
                        class="mt-5 w-full"
                        name="directorId"
                      />
                    </div>

                    <div>
                      <div class="upload-img">
                        <input
                          type="file"
                          class="hidden"
                          ref="uploadImgInput"
                          @change="updateCurrImg"
                          accept="text/csv"
                        />
                        <vs-button
                          type="border"
                          class="btn-blue-border"
                          @click="$refs.uploadImgInput.click()"
                          >Mass Upload
                        </vs-button>
                      </div>
                    </div>

                    <div v-if="showMassUploadButton" class="mt-5">
                      <vs-button @click="submitCsv">Import</vs-button>
                    </div>
                  </div>
                </vs-popup>
              </div>
              <div class="mx-1">
                <vs-button color="primary" :href="templateLink" type="filled">
                  Upload Template
                </vs-button>
              </div>
              <vs-button
                class="mx-1"
                color="primary"
                type="filled"
                @click="addNewTeacher"
                >Add Teacher</vs-button
              >
            </vs-col>

            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="left"
              vs-xs="12"
              vs-sm="3"
              vs-lg="3"
            >
            </vs-col>
          </vs-row>
        </div>
        <template slot="thead">
          <vs-th>Name</vs-th>
          <vs-th>Room</vs-th>
          <vs-th>Role</vs-th>
          <vs-th>Username</vs-th>
          <vs-th>Reset Password</vs-th>
          <vs-th>Account Status</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.fullName">{{ tr.fullName | capitalize }}</vs-td>
            <vs-td :data="tr.room">{{ tr.room | capitalize }}</vs-td>
            <vs-td :data="tr.role">
              {{
                tr.role === "teacher"
                  ? tr.role
                  : tr.role.replace("T", " T") | capitalize
              }}</vs-td
            >

            <vs-td :data="tr.username">{{ tr.email }}</vs-td>

            <vs-td :data="tr._id">
              <a href="#" @click="resetPassword(tr._id)">Reset Password</a>
            </vs-td>
            <vs-td :data="tr.accountStatus">
              <vs-button
                class="mr-4 mt-1"
                :color="tr.accountStatus == 'Active' ? 'success' : 'danger'"
                >{{ tr.accountStatus }}
              </vs-button>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <a
                href="#"
                class="nav-link d-flex align-items-center active"
                @click.stop="viewHandler(tr._id)"
              >
                <feather-icon
                  icon="EyeIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current icon-eye"
                />
              </a>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
    <scroll-loader :loader-method="getTeacherData" :loader-enable="loadMore">
      <div></div>
    </scroll-loader>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions } from "vuex";
import ScrollLoader from "vue-scroll-loader";
import vSelect from "vue-select";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

Vue.use(ScrollLoader);

export default {
  components: {
    "v-select": vSelect
  },
  data() {
    return {
      showMassUploadButton: false,
      image: "",
      csvUpload: {
        config: {
          uploadCsvUrl:
            "http://localhost:3000/api/v1/centerAdmin/teacher/import",
          popupToUploadCsv: false,
          headers: {
            /*"Content-Type": "application/json",
              "Access-Control-Allow-Headers": "Content-Type",*/
          }
        },
        data: {
          learningCenterId: null,
          directorId: null
        }
      },
      templateLink: process.env.VUE_APP_DOMAIN + "/template/teacher.csv",
      loadMore: true,
      isMounted: false,
      totalDocs: 0,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "asc",
        limit: 12,
        page: 0
      },
      teacherData: [],
      serverResponded: false,
      searchQuery: "",
      awaitingSearch: false,
      addNewDataSidebar: false,
      sidebarData: {},
      noDataText: "Currently no teacher available"
    };
  },

  created() {
    this.csvUpload.data.learningCenterId = this.centerAdmin.learningCenterId;
    this.csvUpload.data.directorId = this.centerAdmin.learningCenter.directorId;
  },

  methods: {
    ...mapActions("centerAdmin", [
      "getCenterAdminTeacherList",
      "resetUserPassword",
      "importTeacherCsv"
    ]),

    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.image = input.target.files[0];
        var reader = new FileReader();
        reader.onload = e => {
          // this.logo = e.target.result
        };
        reader.readAsDataURL(input.target.files[0]);
      }
      this.showMassUploadButton = true;
    },

    async submitCsv() {
      await this.$validator.validateAll().then(result => {
        if (result) {
          let data = new FormData();
          data.append("teacher", this.image);
          data.append("learningCenterId", this.centerAdmin.learningCenterId);
          data.append("directorId", this.centerAdmin.learningCenter.directorId);

          this.customError = "";
          this.$vs.loading();
          this.importTeacherCsv(data)
            .then(res => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Teacher Csv Import",
                text: `Teacher file has been imported successfully. ${JSON.stringify(
                  res.data.data
                )}`,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success"
              });
              this.$router.go(this.$router.currentRoute)
            })
            .catch(err => {
              this.$vs.notify({
                title: "Failure",
                text: `Could not import csv file. ${JSON.stringify(
                  err.response.data.message
                )}`,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger"
              });
              this.$vs.loading.close();
              this.image= "";
              this.csvUpload.config.popupToUploadCsv = false;
              this.showMassUploadButton = false;
            })
            .finally(() => {
              this.$vs.loading.close();
              this.image= "";
              this.csvUpload.config.popupToUploadCsv = false;
              this.showMassUploadButton = false;
              this.$router.go(this.$router.currentRoute)
            });
        }
      });
    },

    resetPassword(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want reset password for this user?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!"
      }).then(result => {
        if (result.value) {
          this.acceptPasswordResetAlert(id);
        }
      });
    },
    acceptPasswordResetAlert(id) {
      this.resetUserPassword(id)
        .then(res => {
          this.showChangePasswordSuccess();
        })
        .catch(err => {
          // console.error(err);
        });
    },
    showChangePasswordSuccess() {
      this.$vs.notify({
        title: "Success",
        text: "Reset password mail sent to user.",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "success"
      });
    },
    async getTeacherData(search = null) {
      this.dataTableParams.search = this.searchQuery;
      this.dataTableParams.learningCenterId = this.centerAdmin.learningCenterId;
      this.dataTableParams.page++;
      if (search) {
        this.dataTableParams.page = 1;
      }

      this.$vs.loading();
      await this.getCenterAdminTeacherList(this.dataTableParams)
        .then(res => {
          const a = res.data.data.docs;
          if (search) {
            this.teacherData = res.data.data.docs;
          } else {
            a.forEach(item => {
              this.teacherData.push(item);
            });
          }
          if (this.teacherData.length == 0) {
            this.noDataText =
              "Currently no teacher available";
          }
          this.$vs.loading.close();
        })
        .catch(err => {
          this.$vs.loading.close();
        });
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getTeacherData();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getTeacherData();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getTeacherData();
    },
    viewHandler(id) {
      this.$router.push({
        name: "center-admin-teachers-view",
        params: { id: id }
      });
    },
    addNewTeacher(id) {
      this.$router.push({ name: "center-admin-teacher-add" });
    },
    editDetailHandler(data, id) {
      this.sidebarData = data;
      this.dataId = id;
    },
    refreshData() {},

    successUpload(data) {
      // console.log(data);
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur"
      });
      this.csvUpload.config.popupToUploadCsv = false;
    },

    errorUpload(e) {
      // console.log(e)
      this.$vs.notify({
        color: "danger",
        title: "Upload failure",
        text: "Lorem ipsum dolor sit amet, consectetur"
      });
    }
  },

  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
    centerAdmin() {
      return this.$store.state.AppActiveUser;
    }
  },
  watch: {
    // "dataTableParams.page": function (newVal, oldVal) {
    //   if (newVal !== oldVal) {
    //     this.dataTableParams.page = newVal;
    //     this.getTeacherData();
    //   }
    // },
    "dataTableParams.limit": function(newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getTeacherData();
      }
    },
    "dataTableParams.sort": function(newSort, oldSort) {
      if (newSort !== oldSort) {
        this.dataTableParams.page = 1;
        this.dataTableParams.sort = newSort;
        this.getTeacherData();
      }
    },
    searchQuery(val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.getTeacherData("search");
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    }
  }
};
</script>

<style>
.vs-input--input.hasIcon:focus + .vs-input--placeholder {
  display: none !important;
}
</style>
